import React, { useContext, useEffect, useRef, useState } from "react";
import * as S from "./styles";
import ChatRow from "./ChatRow";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { CloseIcon, SendIcon } from "../../../assets/svg";
import { SocketContext } from "../../../providers/SocketProvider";

const Chatbox = ({ closeChatbox, poolData = {}, roomId, roomChats = [] }) => {
  const [userInput, setUserInput] = useState("");
  const { socket } = useContext(SocketContext);

  const nickColors = ["#68D773", "#D15D52", "#FFDE69", "#71CCFF", "#CD42FB", "#E6870A", "#C1D300", "#7B6FFF"];

  const colorMap = {};

  function uuidToIndex(uuid = "", range = 8) {
    // Create a simple hash from the UUID
    if (!uuid) return 0; // If no ID for any reason, show the first color
    if (colorMap[uuid]) return colorMap[uuid];
    let hash = 0;
    for (let i = 0; i < uuid.length; i++) {
      const char = uuid.charCodeAt(i);
      hash = (hash * 31 + char) % 0xffffffff; // Keep hash manageable
    }

    // Map the hash to an index within the range
    const resultColor = hash % range;
    colorMap[uuid] = resultColor;
    return resultColor;
  }

  function getUserColor(chat) {
    if (chat?.message?.type === "system") return "#FFDE69";
    return nickColors[uuidToIndex(chat.userId)];
  }

  const inputRef = useRef();
  const scrollToRef = useRef();

  useEffect(() => {
    if (scrollToRef && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [roomChats]);

  // Scrolling to last message when first visiting the section
  useEffect(() => {
    if (scrollToRef && scrollToRef?.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (inputRef && inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  function sendMessage() {
    socket.emit("sendRoomMessage", {
      type: "text",
      value: userInput,
      roomId: roomId,
    });
    setUserInput("");
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }

  function handleUserInput(e) {
    setUserInput(e.target?.value);
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      sendMessage();
    }
  }

  return (
    <S.ChatBox>
      <S.ChatBoxHeader>
        <S.Flex>
          {/* TODO: Show game image below */}
          {/* <MessagesSquareIcon />  */}
          <S.ChatHeaderImg src={poolData?.assets?.gameThumbnail} />
          <S.HeaderTextContainer>
            <div>{poolData?.name}</div>
            <small>ID: {poolData?.id}</small>
          </S.HeaderTextContainer>
        </S.Flex>

      <S.CloseChatButton onClick={closeChatbox} >
        <CloseIcon />
        </S.CloseChatButton>
      </S.ChatBoxHeader>
      <S.ChatMessagesContainer>
        {roomChats.map(chat => (
          <ChatRow chat={chat} color={getUserColor(chat)} />
        ))}
        <div style={{ visibility: "hidden" }} ref={scrollToRef} />
      </S.ChatMessagesContainer>
      <S.InputContainer>
        <S.ChatInput ref={inputRef} value={userInput} onKeyDown={handleKeyDown} onChange={handleUserInput} />
        <S.SendButton onClick={sendMessage}>
          <SendIcon />
        </S.SendButton>
      </S.InputContainer>
    </S.ChatBox>
  );
};

export default Chatbox;
