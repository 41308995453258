import React from "react";
import * as S from "./styles";

const ChatRow = ({ chat, color = "#7B6FFF" }) => {
  const {
    message = {},
    userNick,
  } = chat;
  if (message.type === "text")
    return (
      <S.MessageRow color={color}>
        <span>{userNick}:</span>
        {message.value}
      </S.MessageRow>
    );

  if (message.type === `system`)
    return (
      <S.NotifRow>
        {/* {chatStatus === `STARTED`
          ? `You're now chatting with ${participant}`
          : `Your chat with ${participant} has ended`} */}
        {/* <span>{userNick}:</span> */}
        {message.value}
      </S.NotifRow>
    );

  return <></>;
};

export default ChatRow;
